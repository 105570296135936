import React from "react";
import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Tnc = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 justify-start items-center w-full h-full"
      >
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("tnc.title")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.membership")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-6 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_1_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_2_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_1_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_2_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_2_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_2_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_3_4_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 ">
              {i18n.t("tnc.membership_1_5_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_5_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_2_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_3_1")}
            </Text>

            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.privacy")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_3")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_1")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_4")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_2")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_5")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_6")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_5")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_5")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_6")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_4_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_5_1")}
            </Text>

            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_5")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_5_1_6")}
              </Text>
            </View>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_7")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_8")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_6")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_9")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_10")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_11")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_1")}
            </Text>
          </View>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Tnc;
