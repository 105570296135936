import React, { Component, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Image, ImageBackground, TextInput, SafeAreaView, Button } from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import AsyncStorage from "./AsyncStorage";

function Header(props) {
  const navigation = useNavigation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    AsyncStorage.getData("mCode").then((value) => setIsLoggedIn(!!value));
  }, []);

  const backAction = () => {
    if (props.backTo) navigation.navigate(props.backTo);
    else if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate(isLoggedIn ? "Profile" : "Login");
  };

  // const logoutAction = () => {
  //   if (props.logout) {
  //     navigation.navigate(props.logout);
  //   } else {
  //     navigation.goBack();
  //   }
  // };

  //console.log({ isLoggedIn });

  return (
    <View className="w-full bg-white items-center justify-center flew-row p-4 relative content-center md:hidden">
      <TouchableOpacity
        onPress={() => {
          backAction();
        }}
        className="absolute left-4 top-2"
      >
        <FontAwesomeIcon name="angle-left" className="text-black text-4xl"></FontAwesomeIcon>
      </TouchableOpacity>

      <Text className="text-[#121212] text-lg font-bold">{props.title}</Text>

      {/* <TouchableOpacity
        onPress={() => {
          logoutAction();
        }}
        className="absolute right-4 top-3"
      >
        <FontAwesomeIcon name="sign-out" className="text-black text-3xl" />
      </TouchableOpacity> */}
    </View>
  );
}

export default Header;
