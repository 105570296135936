import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  Modal,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import i18n from "../components/i18n";
import RNPickerSelect from "react-native-picker-select";
import AsyncStorage from "../components/AsyncStorage";

const Footer = (props) => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState(false);
  const [langSwitch, setLangSwitch] = useState(false); // to handle the Modal
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (value) {
        setIsLoggedIn(true);
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const switchLang = (lang) => {
    setIsLoading(true);
    setLanguageErr(false);
    if (language == "") {
      setLanguageErr(true);
      return;
    }

    i18n.locale = lang;

    AsyncStorage.storeData("lang", lang);
    location.reload();

    setIsLoading(false);
    setLangSwitch(false);
  };

  const logoutHandling = () => {
    AsyncStorage.removeValue("mCode");
    navigation.push("Login");
  };

  return (
    <View className="!flex w-full items-center justify-center bottom-0 fixed">
      {isLoading && (
        <View
          className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <View className="bg-brandgold w-full md:h-7 flex flex-col justify-center md:flex-row items-center">
        <View className="flex flex-row justify-center items-center flex-wrap">
          <TouchableOpacity
            onPress={() => {
              navigation.push("AboutUs");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.aboutUs")}
            </Text>
          </TouchableOpacity>

          {isLoggedIn && (
            <TouchableOpacity
              onPress={() => {
                navigation.push("Profile");
              }}
            >
              <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
                {i18n.t("footer.profile")}
              </Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            onPress={() => {
              navigation.push("FAQ");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.faq")}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              navigation.push("Tnc");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.tnp")}
            </Text>
          </TouchableOpacity>
        </View>

        <View className="flex flex-row justify-center md:justify-end items-center flex-wrap">
          <TouchableOpacity
            onPress={() => {
              navigation.push("Outlets");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.pr")}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              navigation.push("Privilege");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("header.PRivileges")}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              window.open("https://estore.imperialtreasure.com/");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.eStore")}
            </Text>
          </TouchableOpacity>
        </View>

        <View className="flex flex-row justify-center md:justify-end items-center flex-wrap">
          <TouchableOpacity
            onPress={() => {
              window.open("https://www.imperialtreasure.com/happenings");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("header.highlights")}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              window.open("https://www.imperialtreasure.com/contact");
            }}
          >
            <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
              {i18n.t("footer.contactUs")}
            </Text>
          </TouchableOpacity>

          {isLoggedIn && (
            <TouchableOpacity
              onPress={() => {
                logoutHandling();
              }}
            >
              <Text className="text-xs color-[#111111] uppercase leading-7 mx-3 font-Crimson">
                {i18n.t("profile.logout")}
              </Text>
            </TouchableOpacity>
          )}
        </View>

        <View className="flex flex-row justify-center md:justify-end items-center flex-wrap mb-1">
          <TouchableOpacity
            onPress={() => {
              window.open("https://m.facebook.com/imperialtreasuresg");
            }}
          >
            <ImageBackground
              source={require("../assets/icons/fb-icon.png")}
              resizeMode="contain"
              className="w-4 h-4 ml-4"
            ></ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              window.open("https://www.instagram.com/imperialtreasuresg/");
            }}
          >
            <ImageBackground
              source={require("../assets/icons/ig-icon.png")}
              resizeMode="contain"
              className="w-4 h-4 ml-4"
            ></ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            // onPress={() => {
            //   navigation.push("LanguageSetting");
            // }}
            onPress={() => setLangSwitch(true)}
          >
            <ImageBackground
              source={require("../assets/icons/lang-icon.png")}
              resizeMode="contain"
              className="w-4 h-4 ml-4 mr-3"
            ></ImageBackground>

            {/* <FontAwesomeIcon
              name="language"
              resizeMode="contain"
              className="ml-4 text-black text-xl md:hidden"
            ></FontAwesomeIcon> */}
          </TouchableOpacity>
        </View>
      </View>

      <Modal animationType="slide" visible={langSwitch} transparent={true}>
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000050]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => setLangSwitch(false)}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>
            <View className="flex">
              <Text className="flex justify-center items-center mt-5 text-base text-2xl md:text-4xl text-brandgold md:text-black font-semibold font-Crimson">
                {i18n.t("languageSetting.title")}
              </Text>
            </View>

            <Text className="text-[#4E5969] text-lg mt-4 mb-2 font-PingFangSC">
              {i18n.t("languageSetting.language_preference")}
            </Text>

            <RNPickerSelect
              value={language}
              onValueChange={(value) => setLanguage(value)}
              items={[
                {
                  label: i18n.t("languageSetting.language_preference_opt2"),
                  value: "en",
                },
                {
                  label: i18n.t("languageSetting.language_preference_opt3"),
                  value: "zh",
                },
              ]}
              placeholder={{
                label: i18n.t("languageSetting.language_preference_opt1"),
                value: "",
              }}
              style={pickerSelectStyles}
              useNativeAndroidPickerStyle={false}
            />

            {languageErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("languageSetting.language_preference_errMsg")}
              </Text>
            )}

            <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-6">
              <TouchableOpacity
                className="w-auto h-8 max-h-8 bg-brandgold md:mt-8 mt-4 px-4 "
                onPress={() => switchLang(language)}
              >
                <Text className="text-white w-full 10h- leading-8 text-center font-PingFangSC">
                  {i18n.t("languageSetting.submit")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <View className="flex flex-row md:justify-center justify-end w-full bg-[#111111] h-4">
        <Text className="text-[10px] text-white leading-4 mr-3 flex items-center">
          {i18n.t("footer.copyright")}
        </Text>
      </View>
    </View>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default Footer;
