import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useFonts } from "expo-font";
import Login from "./views/login";
import Privilege from "./views/privilege";
import Profile from "./views/profile";
import Coupon from "./views/coupon";
import ForgetPassword from "./views/forgetPassword";
import ResetPassword from "./views/resetPassword";
import FAQ from "./views/faq";
import Tnc from "./views/tnc";
import AboutUs from "./views/aboutUs";
import Outlets from "./views/outlets";
import EditProfile from "./views/editProfile";
import UpdateMobile from "./views/updateMobile";
import UpdateEmail from "./views/updateEmail";
import FriendReferral from "./views/friendReferral";
import Transaction from "./views/transaction";
import ContactUs from "./views/contactUs";
import LanguageSetting from "./views/languageSetting";
import SignUp from "./views/signUp";

const Stack = createNativeStackNavigator();

const config = {
  screens: {
    Login: "",
    SignUp: "sign-up",
    Privilege: "privilege",
    Profile: "profile",
    Coupon: "coupon",
    ForgetPassword: "forget-password",
    ResetPassword: "reset-password",
    FAQ: "faq",
    Tnc: "tnc",
    AboutUs: "about-us",
    Outlets: "outlets",
    EditProfile: "edit-profile",
    UpdateMobile: "update-mobile",
    UpdateEmail: "update-email",
    FriendReferral: "friend-referral",
    Transaction: "transaction",
    ContactUs: "contact-us",
    LanguageSetting: "LanguageSetting",
  },
};

const linkingConfig = {
  //prefixes: [prefix, "https://app.imperialtreasure.com/hk/"],
  config,
};

export default function App() {
  const [loaded] = useFonts({
    "CrimsonText-Regular": require("./assets/fonts/CrimsonText-Regular.ttf"),
    "NunitoSans-Regular": require("./assets/fonts/NunitoSans-Regular.ttf"),
    "PingFang-SC-Regular": require("./assets/fonts/PingFang-SC-Regular.ttf"),
  });

  if (!loaded) {
    return null;
  }

  return (
    <NavigationContainer linking={linkingConfig}>
      <Stack.Navigator
        initialRouteName="Login"
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen
          name="Login"
          component={Login}
          options={{ title: "Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{ title: "Registration - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="Privilege"
          component={Privilege}
          options={{ title: "Privileges - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="Profile"
          component={Profile}
          options={{
            title: "Profile - Imperial Treasure Club",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="Coupon"
          component={Coupon}
          options={{ title: "Coupon - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="ForgetPassword"
          component={ForgetPassword}
          options={{ title: "Forget Password - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="ResetPassword"
          component={ResetPassword}
          options={{ title: "Reset Password - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="FAQ"
          component={FAQ}
          options={{ title: "FAQ - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="Tnc"
          component={Tnc}
          options={{ title: "Terms And Privacy - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="AboutUs"
          component={AboutUs}
          options={{ title: "About Us - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="Outlets"
          component={Outlets}
          options={{ title: "Outlets - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="EditProfile"
          component={EditProfile}
          options={{ title: "Edit Profile - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="UpdateMobile"
          component={UpdateMobile}
          options={{ title: "Update Mobile - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="UpdateEmail"
          component={UpdateEmail}
          options={{ title: "Update Email - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="FriendReferral"
          component={FriendReferral}
          options={{ title: "Friend Referral - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="Transaction"
          component={Transaction}
          options={{ title: "Transaction - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="ContactUs"
          component={ContactUs}
          options={{ title: "Contact Us - Imperial Treasure Club" }}
        />
        <Stack.Screen
          name="LanguageSetting"
          component={LanguageSetting}
          options={{ title: "Language Setting - Imperial Treasure Club" }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
