import React, { useState, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import MobileHeader from "../components/mHeader";
import InsideHead from "../components/insideHeader";
import Footer from "../components/footer";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import { useNavigation } from "@react-navigation/native";
import IntlPhoneInput from "../components/IntlPhoneInput";
import queryString from "query-string";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const ResetPassword = () => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [token, setToken] = useState("");
  const [resetErr, setResetErr] = useState(false);
  const [resetErrMsg, setResetErrMsg] = useState("Error");
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [source, setSource] = useState("");

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setToken(parsed.token);

    // console.log("parsed", parsed);

    if (parsed.source == "email") {
      setSource(parsed.source);
    } else {
      setSource("mobile");
    }
  }, []);

  const submitReset = () => {
    let errors = [];
    setIsLoading(true);
    setPhoneErr(false);
    setPasswordErr(false);
    setConfirmPasswordErr(false);
    setPasswordFormatErr(false);
    setResetErr(false);
    setEmailErr(false);

    console.log(token);

    if (source == "email") {
      console.log("email handling...");
      if (!validEmail(email) || email == "") {
        errors.push("err");
        setEmailErr(true);
        setIsLoading(false);
      }
    } else {
      console.log("mobile handling...");

      // Not need input phone number !!!!
      // if (!validPhone) {
      //   errors.push("err");
      //   setPhoneErr(true);
      //   setIsLoading(false);
      // }
    }

    if (password == "") {
      errors.push("err");
      setPasswordErr(true);
      console.log("errors");
      if (errors.length > 0) {
        console.log(errors);
      }
    } else if (password != confirmPassword) {
      errors.push("err");
      setConfirmPasswordErr(true);
      console.log("errors");
      if (errors.length > 0) {
        console.log(errors);
      }
    } else if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/
      )
    ) {
      errors.push("err");
      setPasswordFormatErr(true);
      console.log("errors");
      if (errors.length > 0) {
        console.log(errors);
      }
    }

    console.log("No error121321231313");

    if (errors.length > 0) {
      console.log(errors);
    }

    if (!errors.length) {
      console.log("No error");

      if (source == "email") {
        let data = {
          email: email,
          token: token,
          password: password,
        };

        console.log("ready to reset pw");
        resetPassword(data, (reset) => {
          // console.log("data :", data);
          console.log("reset :", reset);
          if (reset.status == "success") {
            setStep(2);
            setIsLoading(false);
          } else {
            setResetErrMsg(reset.message);
            setResetErr(true);
            setIsLoading(false);
          }
        });
      }

      if (source != "email" || source == "mobile") {
        console.log("Mobile now");
        let data = {
          mobile: dialCode + phone.replace(/\s/g, ""),
          token: token,
          password: password,
        };
        resetPassword(data, (reset) => {
          // console.log("data :", data);
          console.log("reset :", reset);
          if (reset.status == "success") {
            setStep(2);
            setIsLoading(false);
          } else {
            setResetErrMsg(reset.message);
            setResetErr(true);
            setIsLoading(false);
          }
        });
      }
    } else {
      setIsLoading(false);
    }
  };

  const resetPassword = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "resetPass");

    if (source == "email") {
      formData.set("email", data.email);
      formData.set("token", data.token);
      formData.set("password", data.password);
    }

    if (source != "email" || source == "mobile") {
      formData.set("mobile", data.mobile);
      formData.set("token", data.token);
      formData.set("password", data.password);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const onChangeText = ({
    dialCode,
    unmaskedPhoneNumber,
    phoneNumber,
    isVerified,
  }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <SafeAreaView
      className="bg-white md:bg-[#F5F5F5] h-full min-h-full"
      pointerEvents={!isLoading ? "auto" : "none"}
    >
      {isLoading && (
        <View
          className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          style={{ position: "fixed" }}
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHead />
      <NewMobileHeader />
      <MobileHeader title={i18n.t("resetPassword.title")} backTo={"Login"} />

      <ScrollView className="w-full flex-1 h-auto">
        <View className="w-full px-4 md:w-4/5 md:max-w-3xl md:h-auto md:m-auto md:bg-white md:p-5 md:mt-6 mb-10">
          <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson">
            {i18n.t("resetPassword.reset_password")}
          </Text>
          <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
          {step == 1 && (
            <>
              {source == "email" && (
                <View>
                  <Text className="color-[#4E5969] text-xs mt-6 hover:border-none font-PingFangSC">
                    {i18n.t("resetPassword.email")}
                  </Text>
                  <TextInput
                    placeholder={i18n.t("resetPassword.email_input")}
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={email}
                    onChangeText={setEmail}
                  ></TextInput>
                  {emailErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("resetPassword.email_errMsg")}
                    </Text>
                  )}
                </View>
              )}

              {source == "mobile" && (
                <View>
                  <Text className="color-[#4E5969] text-xs mt-6 hover:border-none font-PingFangSC">
                    {i18n.t("resetPassword.mobile")}
                  </Text>
                  <IntlPhoneInput
                    onChangeText={onChangeText}
                    defaultCountry={API_REGION}
                    containerStyle={{ flex: 1, flexDirection: "row" }}
                    flagStyle={{ display: "none" }}
                    phoneInputStyle={{
                      color: "#86909C",
                      height: 34,
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 15,
                    }}
                    dialCodeTextStyle={{
                      color: "#86909C",
                      height: 34,
                      lineHeight: 34,
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 15,
                    }}
                    placeholder={phone}
                  />
                  {phoneErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("resetPassword.mobile_errMsg")}
                    </Text>
                  )}
                </View>
              )}

              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                {i18n.t("resetPassword.new_password")}
              </Text>
              <TextInput
                placeholder=""
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                secureTextEntry={true}
                value={password}
                onChangeText={setPassword}
              ></TextInput>

              {passwordErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("resetPassword.new_password_errMsg1")}
                </Text>
              )}
              {passwordFormatErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("resetPassword.new_password_errMsg2")}​
                </Text>
              )}

              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                {i18n.t("resetPassword.confirm_password")}
              </Text>
              <TextInput
                placeholder=""
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                secureTextEntry={true}
                value={confirmPassword}
                onChangeText={setConfirmPassword}
              ></TextInput>

              {confirmPasswordErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("resetPassword.confirm_password_errMsg")}
                </Text>
              )}

              {resetErr && (
                <Text className="color-red-500 my-1">{resetErrMsg}</Text>
              )}

              <TouchableOpacity
                className="w-full h-10 max-h-10 bg-brandgold mt-4"
                onPress={() => submitReset()}
              >
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">
                  {i18n.t("resetPassword.reset_password2")}
                </Text>
              </TouchableOpacity>
            </>
          )}

          {step == 2 && (
            <>
              <Text className="text-sm text-[#494949] mt-6 mb-0 font-NunitoSans">
                {i18n.t("resetPassword.has_been_reset")}
              </Text>
              <TouchableOpacity
                className="w-auto max-w-[160px] h-8 max-h-8 bg-brandgold mt-2 mr-3 px-4 mb-6"
                onPress={() => navigation.push("Login")}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">
                  {i18n.t("resetPassword.login")}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </ScrollView>
      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

export default ResetPassword;
